import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "sustainable-development"
};
import SustainableDevelopmentBanner from "@/views/about/sustainable-development-module/sustainable-development-banner.vue";
import SustainableDevelopmentContent from "@/views/about/sustainable-development-module/sustainable-development-content.vue";
export default {
  __name: 'sustainable-development',
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(SustainableDevelopmentBanner), _createVNode(SustainableDevelopmentContent)]);
    };
  }
};